<template>
<div class="positionDetail">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
        <div class="content" v-if="config.c1&&config.c1.isShow">
            <!-- <p class="title">{{position}}</p> -->
            <div v-html="content"></div>
        </div>
        <p class="content" v-if="config.c2&&config.c2.isShow">
            <span>{{config.c2.tip}}</span>
            <a :href="'mailto:'+config.c2.email">{{config.c2.email}}</a>
        </p>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getJobRecruitDetailById
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: 'positionDetail',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            content: "",
            position: '',
            seoTitle: '',
            seoKeyword: '',
            seoDesc: '',
        }
    },
    computed: {},
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'positionDetail/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c1 = this.config.c1
                    if (c1 && c1.isShow) {
                        this.getJobRecruitDetailById();
                    }
                }
            })
        },
        getJobRecruitDetailById() {
            getJobRecruitDetailById(this.$route.query.id).then(res => {
                if (res.success && res.result) {
                    this.position = res.result.position,
                    this.content = res.result.content,
                    this.seoTitle = res.result.seoTitle,
                    this.seoKeyword = res.result.seoKeyword,
                    this.seoDesc = res.result.seoDesc

                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.positionDetail {
    .content {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
        @media (max-width:767px) {
            margin-top: .3rem;
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .titleSecond {
            color: #333333;
            font-size: 0.22rem;
            margin-bottom: 0.3rem;
        }

        .text {
            color: #333333;
            font-size: 0.2rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: justify;
        }
    }

    .content {
        color: #333333;
        font-size: 0.2rem;
        line-height: 2;
		@media (max-width:767px) {
			font-size: 0.4rem;
		}
        a {
            color: #ff1932;
        }
    }
}
</style>
